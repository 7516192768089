import React, {FC, useEffect, useState} from 'react'
import {KTCard, toAbsoluteUrl} from "../../../_metronic/helpers";
import {SubscriptionTable} from "../../modules/tables/userSubs/SubscriptionTable";
import {getDashboardData} from "../../requests/_requests";
import {DashboardData} from "../../_models";
import {SubscriptionPageList} from "../../modules/tables/userSubs/SubscriptionPageList";

const Dashboard: FC = () => {

    const [data, setData] = useState<DashboardData>()

    useEffect(() => {
        document.title = 'داشبورد'
        getDashboardData().then(res => {
            if (res.status === 200) {
                setData(res.data?.data)
            }
        })
    }, []);



    return <>
        <KTCard className='my-5 bg-gray-100 mb-10 d-md-none'>
            <div className="p-10 pb-0">
                <h2 className="text-primary">مدیریت اشتراک ها</h2>
            </div>
            <SubscriptionPageList/>
        </KTCard>
        <div className='row justify-content-evenly gap-5'>
            <div className='col-md-7 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-primary fs-3 fw-bold'>
                        مفتخر میزبانی سرویس ترید شما در بزرگترین و حرفه ای ترین سرویس IP ثابت ایران هستیم!
                    </div>
                    <p className="fs-8">به حساب کاربری کاور تریدر خود خوش آمدید :)</p>
                </div>
                <img
                    className='position-relative'
                    style={{top: "-30px"}}
                    src={toAbsoluteUrl('/media/happy-announcement.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-4 fw-bold'>
                        ما را در اینستاگرام دنبال کنید!
                    </div>
                    <a target="_blank" className='btn btn-secondary btn-small-ct text-nowrap' href={data?.instagram}>صفحه اینستگرام کاور
                        تریدر</a>
                </div>
                <img
                    className='position-relative'
                    style={{top: "-30px"}}
                    src={toAbsoluteUrl('/media/loving-it.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-7 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-3 fw-bold'>
                        آدرس وبسایت رسمی کاور تریدر!
                    </div>
                    <a target="_blank" className='btn btn-small-ct btn-secondary text-nowrap' href={data?.website}>covertrader.com</a>
                </div>
                <img
                    className='p-5'
                    src={toAbsoluteUrl('/media/website.svg')}
                    alt=''
                />
            </div>
            <div className='col-md-4 col-12 card bg-gray-100 d-flex flex-row h-175px justify-content-between'>
                <div className='px-5 py-10'>
                    <div className='text-gray-400 fs-4 fw-bold'>
                        عضویت در کانال تلگرام کاور تریدر!
                    </div>
                    <a target="_blank" className='btn btn-secondary btn-small-ct text-nowrap' href={data?.telegram}>کانال تلگرام کاور تریدر</a>
                </div>
                <img
                    className='p-5'
                    src={toAbsoluteUrl('/media/social.svg')}
                    alt=''
                />
            </div>

        </div>
        <KTCard className='m-3 my-5 d-none d-md-block'>
            <div className="p-10 pb-0">
                <h4>خلاصه ای از وضعیت اشتراک های شما</h4>
            </div>
            <SubscriptionTable/>
        </KTCard>
    </>
}
export default Dashboard