import React, {FC, useEffect, useState} from 'react'
import {EnableSidebar, PageTitle} from '../../../_metronic/layout/core'
import {KTCard, KTCardBody, toAbsoluteUrl} from "../../../_metronic/helpers";
import axios from "axios";
import {getClientSettings, getServersList} from "../../requests/_requests";
import {ServerModel, SettingModel} from "../../modules/auth";

const InstructionPage: FC = () => {

    const breadcrumbs = [
        {
            title: 'پنل کاربری',
            path: "/",
            isActive: true
        },
        {
            title: 'آموزش، اپلیکیشن ها، سرور ها',
            path: "/instructions",
            isActive: true
        }
    ]

    const [servers, setServers] = useState<ServerModel[]>([])
    const [settings, setSettings] = useState<SettingModel[]>([])

    useEffect(() => {
        document.title = 'آموزش، اپلیکیشن ها، سرور ها'
        getClientSettings().then(res => {
            setSettings(res.data.data)
        }).catch(() => {})

        getServersList().then(res => {
            setServers(res.data.data)
        }).catch(() => {})

    }, []);


    const handleDownload = async (fileUrl) => {
        console.log(fileUrl)
        if (!fileUrl) {
            console.error('File URL is required');
            return;
        }

        try {
            // Fetch the file as a Blob for better compatibility
            const response = await fetch(fileUrl);
            if (!response.ok) {
                throw new Error(`Failed to fetch file: ${response.statusText}`);
            }

            const blob = await response.blob();
            const url = window.URL.createObjectURL(blob);

            // Create a temporary anchor element
            const anchor = document.createElement('a');
            anchor.href = url;
            anchor.download = 'downloaded-file';

            // Append the anchor to the body (Safari needs it)
            document.body.appendChild(anchor);

            // Trigger the download
            anchor.click();

            // Cleanup
            document.body.removeChild(anchor);
            window.URL.revokeObjectURL(url);
        } catch (error) {
            console.error('Error downloading file:', error);
        }
    };

    const renderServers = () => {
        return servers.map(server => {
            let splitURL = server.openVpnUdpFile.split('/');
            let filename = splitURL[splitURL.length - 1];
            return (
                <div key={server.id} className={'col-6 container'}>
                    <div className={'row align-items-center shadow mb-4 mx-2 px-4 py-2 rounded-4'}>
                        <div className={'col'} style={{display: 'flex', flexDirection: 'row', alignItems: 'center', flex: '1 1 0'}}>
                            <img width={54} src={server.flag} alt=""/>
                            <p className={'text-gray-800 fs-6 ms-4 mb-0'}>{server.nameFa}</p>
                        </div>
                        <a target='_blank' download={filename} href={server.openVpnUdpFile} className="col-auto btn btn-primary btn-sm" style={{height: 'fit-content'}}>
                            دریافت فایل اتصال
                            &nbsp;<i className="bi bi-cloud-arrow-down-fill fs-2"></i>
                        </a>
                    </div>
                </div>
            )
        })
    }

    const findSettingByKey = (key: string): SettingModel|undefined => settings.find(s => s.key == key)

    return (
        <EnableSidebar>
            <PageTitle breadcrumbs={breadcrumbs}>
                آموزش، اپلیکیشن ها، سرور ها
            </PageTitle>
            <div className="w-75">
                در این صفحه می‌توانید آموزش های اتصال و استفاده از سرویس را بررسی کنید، همچنین اپلیکیشن های مورد نیاز برای اتصال و سرور های مورد نیاز برای اتصال را دریافت کنید.
            </div>
            <KTCard className='my-5 bg-gray-100'>
                <div className="p-10 pb-0">
                    <h2 className="text-primary">آموزش های اتصال و استفاده</h2>
                </div>
                <KTCardBody className='py-4'>
                    <div className='d-flex'>
                        <div className='w-75 w-100'>
                            <p>
                                با توجه به سیستم عامل خود، با کلیک کردن روی دکمه های زیر می‌توانید اپلیکیشن اتصال خود را دانلود و نصب کنید. همچینن فایل های اتصال سرور در همین صفحه به ترتیب کشور ها و لوکیشن ها قرار داده شده اند. پس از دانلود اپلیکیشن ها می‌توانید به صفحه آموزش ها مراجعه کنید، تمامی فرایند نصب و استفاده و نکات مهم در بخش آموزش ها برای شما قرار داده شده است.
                            </p>
                            <a target={'_blank'} href={findSettingByKey('android_app_link')?.value} style={{margin: '8px 4px 4px 4px'}} className="btn btn-primary btn-sm">اپلیکیشن اتصال Andriod</a>
                            <a target={'_blank'} href={findSettingByKey('ios_app_link')?.value} style={{margin: '8px 4px 4px 4px'}} className="btn btn-primary btn-sm">اپلیکیشن اتصال IOS</a>
                            <a target={'_blank'} href={findSettingByKey('windows_app_link')?.value} style={{margin: '8px 4px 4px 4px'}} className="btn btn-primary btn-sm">اپلیکیشن اتصال Windows</a>
                            <a target={'_blank'} href={findSettingByKey('macos_app_link')?.value} style={{margin: '8px 4px 4px 4px'}} className="btn btn-primary btn-sm">اپلیکیشن اتصال MacOS</a>
                            <a target={'_blank'} href={findSettingByKey('connection_video_tutorial')?.value} style={{margin: '8px 4px 4px 4px'}} className="btn btn-secondary btn-sm">اموزش ویدیویی اتصال</a>
                        </div>
                        <div className='w-md-25'>
                            <img
                                className='position-md-absolute d-none d-md-flex'
                                style={{top: "-20px"}}
                                src={toAbsoluteUrl('/media/guy-phone.svg')}
                                alt='آموزش ها'
                            />
                        </div>
                    </div>
                </KTCardBody>
            </KTCard>

            <KTCard className={'d-flex card my-8'}>
                <div className="p-10 pb-0 border-1 border-bottom pt-5 pb-5">
                    <h2 className={'p-0 m-0'}>دریافت فایل های اتصال آی پی ثابت کاور تریدر</h2>
                </div>
                <KTCardBody className='py-4 container'>
                    <div className={'row'}>
                        {renderServers()}
                    </div>
                </KTCardBody>

            </KTCard>
        </EnableSidebar>
    )
}

export {InstructionPage}
