import {FC} from 'react'
import clsx from "clsx";

type Props = {
    status?: 1 | 2 | 3 | 4 | 5
}

const text = (status) => {
    switch (status) {
        case 1:
            return "در انتظار پرداخت"
        case 2:
            return "فعال"
        case 3:
            return "منقضی شده"
        case 4:
            return "مسدود شده"
        case 5:
            return "در انتظار تمدید"
    }
}
const SubStatusCell: FC<Props> = ({status}) => (
    <>
        <div className={clsx(
            'badge badge-light-success fw-bolder p-3',
            {
                'badge-light-warning': status === 1
            },
            {
                'badge-light-success': status === 2
            },
            {
                'badge-light-warning': status === 3
            },
            {
                'badge-light-danger': status === 4
            },
            {
                'badge-light-info': status === 5
            },
        )}
        >
            {text(status)}
        </div>
    </>
)

export {SubStatusCell}
